<template>
  <ul class="advantages-line">
    <li
      v-for="({ title, name }, index) in advantages"
      :key="index"
      class="advantages-line__item"
      :class="`advantages-line__${name}`"
    >
      <span class="advantages-line__icon"></span>
      <p v-html="title"></p>
    </li>
  </ul>
</template>

<script>
import './advantages-line.scss';

export default {
  name: 'AdvantagesLine',
  data() {
    return {
      advantages: [
        {
          name: 'time',
          title:
            'Минимальное время<br class="desktop tablet"/> выполнения заказа <br class="tablet"/><strong>15 минут</strong>'
        },
        {
          name: 'info',
          title:
            'Только <strong>актуальная<br class="tablet"/> информация</strong><br class="desktop"/> из<br class="tablet"/> открытых источников'
        },
        {
          name: 'refund',
          title:
            'В случае отсутствия<br class="tablet"/> заявленной<br class="desktop tablet"/> информации в отчете<br class="tablet"/> мы <strong>вернем<br class="desktop"/> стоимость<br class="tablet"/> отчета</strong>'
        }
      ]
    };
  }
};
</script>
